.w-md-editor-text-pre,
.w-md-editor-text-input,
.w-md-editor-text > .w-md-editor-text-pre {
    font-family: inherit !important;
}

body .w-md-editor-toolbar svg {
    width: 24px !important;
    height: 24px !important;
}

.w-md-editor-toolbar li > button {
    height: 32px !important;
}

.notif {
    position: fixed;
    top: 6px;
    right: 72px;
    z-index: 100000;
}

.notif span {
    color: red;
    font-weight: bold;
}

#popup-root > * {
    z-index: 100000 !important;
}

.notif-trigger {
    border: none;
    background-color: inherit;
    display: flex;
    align-items: center;
    gap: 4px;
}

.notif-message {
    padding: 0 0.5em;
}

.notif-hint {
    color: red;
}

button[data-name='warning'] + .w-md-editor-toolbar-child.active {
    top: 56px;
    right: 6px;
}
